var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow card-body",
      staticStyle: {
        padding: "0px",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "card-body-top",
        },
        [
          _c(
            "span",
            {
              staticClass: "title-avg",
            },
            [_vm._v(_vm._s(_vm.learningPassionData.attendanceTimeHours))]
          ),
          _c(
            "span",
            {
              staticClass: "title-unit mx-2",
            },
            [_vm._v(_vm._s(_vm.$t("hour")))]
          ),
          _c(
            "span",
            {
              staticClass: "title-avg",
            },
            [_vm._v(_vm._s(_vm.learningPassionData.attendanceTimeMins))]
          ),
          _c(
            "span",
            {
              staticClass: "title-unit mx-2",
            },
            [_vm._v(_vm._s(_vm.$t("minute")))]
          ),
          _c(
            "span",
            {
              staticClass: "title-text",
            },
            [_vm._v(_vm._s(_vm.$t("vip.accumulated_class_hours")))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-row card-body-btm flex-wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "card-body-btm-l",
            },
            [
              _c("canvas", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.fullWidth > _vm.PadWidth,
                    expression: "fullWidth > PadWidth",
                  },
                ],
                staticClass: "canvas",
                attrs: {
                  id: "canvas-frequency",
                  width: "320",
                  height: "320",
                },
              }),
              _c("canvas", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.fullWidth <= _vm.PadWidth,
                    expression: "fullWidth <= PadWidth",
                  },
                ],
                staticClass: "canvas",
                attrs: {
                  id: "canvas-frequency-mobile",
                  width: "320",
                  height: "200",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "canvas-text canvas-text-frequency",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "canvas-digital",
                    },
                    [_vm._v(_vm._s(_vm.learningPassionData.thirtyDaysAverage))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "canvas-unit",
                      staticStyle: {
                        "font-size": "21px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(`${_vm.$t("class.class")}/${_vm.$t("day")}`)
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "canvas-title",
                },
                [_vm._v(_vm._s(_vm.$t("vip.learning_frequency")))]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "card-body-btm-r flex-column bd-highlight",
            },
            _vm._l(_vm.frequencyDataList, function (list) {
              return _c(
                "div",
                {
                  key: list.text,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "btm-r-list btm-r-list-frequency d-flex align-items-baseline",
                      class: list.class,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "right-digital col-7",
                        },
                        [
                          _vm._v(_vm._s(list.value)),
                          _c(
                            "span",
                            {
                              staticClass: "right-unit",
                            },
                            [_vm._v(_vm._s(_vm.$t("class.class")))]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "right-text col-5",
                        },
                        [_vm._v(_vm._s(list.text))]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }