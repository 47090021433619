var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "wuwow card-body card-bg",
      },
      [
        _c(
          "div",
          {
            staticClass: "card-body-title",
          },
          [_vm._v(_vm._s(_vm.$t("material.material_analysis")))]
        ),
        _c(
          "div",
          {
            staticClass: "are-progress-chart",
            attrs: {
              id: "barChart",
            },
          },
          [
            _c("bar-chart", {
              attrs: {
                "chart-data": _vm.dataCollection,
                options: _vm.options,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "crowns",
          },
          _vm._l(_vm.barChartLength, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "crown",
              },
              [
                _vm.crownPosition === index
                  ? _c("img", {
                      staticClass: "crown-img",
                      attrs: {
                        src: _vm.crownImg,
                      },
                    })
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }