var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "subtitle",
      },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.layout === _vm.bookAndAttend,
                expression: "layout === bookAndAttend",
              },
            ],
            staticClass: "subtitle-thirty-day",
          },
          [_vm._v(_vm._s(`*${_vm.$t("last_30_days_statistics")}`))]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "reg-progress",
      },
      [
        _c(
          "div",
          {
            staticClass: "wuwow-card d-flex flex-column",
          },
          [
            !_vm.isObjectEmpty(_vm.learningPassionData) &&
            _vm.layout === _vm.attendedClass
              ? _c("attended-class", {
                  attrs: {
                    learningPassionData: _vm.learningPassionData,
                  },
                })
              : _vm.layout === _vm.bookAndAttend
              ? _c("book-and-attend", {
                  attrs: {
                    learningPassionData: _vm.learningPassionData,
                  },
                })
              : _vm.layout === _vm.learningFrequency
              ? _c("learning-frequency", {
                  attrs: {
                    learningPassionData: _vm.learningPassionData,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "d-flex justify-content-center flex-wrap mt-2",
      },
      _vm._l(_vm.passionBtn, function (btn) {
        return _c(
          "div",
          {
            key: btn.text,
          },
          [
            _c(
              "b-button",
              {
                staticClass: "progress-btn mt-2",
                class: [
                  _vm.layout === btn.name ? "btn-selected" : "btn-cancel",
                ],
                attrs: {
                  variant: "outline-primary",
                  size: "sm",
                },
                on: {
                  click: function ($event) {
                    return _vm.onPassionBtn(btn.name)
                  },
                },
              },
              [_vm._v(_vm._s(btn.text))]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }