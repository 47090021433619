var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "skeleton",
    {
      attrs: {
        loading: _vm.loading,
      },
    },
    [
      _c(
        "template",
        {
          slot: "skeleton-template",
        },
        [_c("skeleton-template")],
        1
      ),
      _c(
        "template",
        {
          slot: "origin-template",
        },
        [
          _c(
            "div",
            {
              staticClass: "progress-analysis",
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "card-bg card-left text-center",
                      attrs: {
                        lg: "3",
                        "align-h": "center",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-mobile-left mt-4 mb-3 col-lg-12 col-5",
                        },
                        [
                          !_vm.isObjectEmpty(_vm.challengeInfoData) &&
                          !_vm.isObjectEmpty(_vm.analysisOutlineData)
                            ? _c("vip-current-experience", {
                                attrs: {
                                  challengeInfoData: _vm.challengeInfoData,
                                  analysisOutlineData: _vm.analysisOutlineData,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-lg-12 col-7",
                          staticStyle: {
                            margin: "auto",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.summerRewardEventInfo.passedAt)),
                          ]),
                          !_vm.isObjectEmpty(_vm.analysisOutlineData)
                            ? _c("vip-current-level-class-info", {
                                attrs: {
                                  analysisOutlineData: _vm.analysisOutlineData,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      this.summerRewardEventInfo
                        .fortyClassesCompletionStatus === "passed"
                        ? _c(
                            "div",
                            {
                              staticClass: "col-lg-12 col-7 d-lg-block d-none",
                              staticStyle: {
                                margin: "auto",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "summerCamp",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "summerCamp-title",
                                    },
                                    [
                                      _c("h2", [
                                        _vm._v("暑期密集口說挑戰 證書下載"),
                                      ]),
                                      _c("p", [
                                        _vm._v("證書將於"),
                                        _c("br"),
                                        _vm._v(
                                          "完成 40 堂的 72 小時後開放下載，"
                                        ),
                                        _c("br"),
                                        _vm._v("開放下載截止至12/3"),
                                      ]),
                                    ]
                                  ),
                                  _c("ul", [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "summerCamp-card",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/summerCamp/superkid-certif.svg"),
                                            alt: "certif",
                                          },
                                        }),
                                        _c("h3", [_vm._v("線 上 證 書")]),
                                        _c("p", [
                                          _vm._v("點擊按鈕"),
                                          _c("br"),
                                          _vm._v("下載電子結業證書"),
                                        ]),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn",
                                            attrs: {
                                              type: "button",
                                              disabled:
                                                !_vm.teacherWritedCustomize,
                                            },
                                            on: {
                                              click: _vm.openSummerCampModal,
                                            },
                                          },
                                          [_vm._v("立即下載")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "summerCamp-card",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/summerCamp/superkid-grad.svg"),
                                            alt: "grad",
                                          },
                                        }),
                                        _c("h3", [_vm._v("紙 本 證 書")]),
                                        _c("p", [
                                          _vm._v("完成收件資料填寫，"),
                                          _c("br"),
                                          _vm._v("再送文具禮包"),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "summerCamp-text",
                                            },
                                            [_vm._v("（ 30 個工作天內寄送 ）")]
                                          ),
                                        ]),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn",
                                            attrs: {
                                              type: "button",
                                              disabled:
                                                !_vm.teacherWritedCustomize,
                                            },
                                            on: {
                                              click: _vm.applyPaperCertificate,
                                            },
                                          },
                                          [_vm._v("我要申請")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  this.summerRewardEventInfo.fortyClassesCompletionStatus ===
                  "passed"
                    ? _c(
                        "b-col",
                        {
                          staticClass: "mt-5 summerCamp-col d-lg-none",
                          staticStyle: {
                            "border-radius": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "summerCamp",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "summerCamp-title",
                                },
                                [
                                  _c("h2", [
                                    _vm._v("暑期密集口說挑戰 證書下載"),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "證書將於 完成 40 堂的 72 小時後開放下載，"
                                    ),
                                    _c("br"),
                                    _vm._v("開放下載截止至12/3"),
                                  ]),
                                ]
                              ),
                              _c("ul", [
                                _c(
                                  "li",
                                  {
                                    staticClass: "summerCamp-card",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/summerCamp/superkid-grad.svg"),
                                        alt: "grad",
                                      },
                                    }),
                                    _c("h3", [_vm._v("紙 本 證 書")]),
                                    _c("p", [
                                      _vm._v("完成收件資料填寫，"),
                                      _c("br"),
                                      _vm._v("再送文具禮包"),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "summerCamp-text",
                                        },
                                        [_vm._v("（ 30 個工作天內寄送 ）")]
                                      ),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          type: "button",
                                          disabled: !_vm.teacherWritedCustomize,
                                        },
                                        on: {
                                          click: _vm.applyPaperCertificate,
                                        },
                                      },
                                      [_vm._v("我要申請")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass: "summerCamp-card",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/summerCamp/superkid-certif.svg"),
                                        alt: "certif",
                                      },
                                    }),
                                    _c("h3", [_vm._v("線 上 證 書")]),
                                    _c("p", [
                                      _vm._v("點擊按鈕"),
                                      _c("br"),
                                      _vm._v("下載電子結業證書"),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn",
                                        attrs: {
                                          type: "button",
                                          disabled: !_vm.teacherWritedCustomize,
                                        },
                                        on: {
                                          click: _vm.openSummerCampModal,
                                        },
                                      },
                                      [_vm._v("立即下載")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    {
                      staticClass: "card-right",
                      attrs: {
                        lg: "8",
                        cols: "12",
                        "offset-lg": "1",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-center",
                        },
                        [
                          _c(
                            "h1",
                            {
                              staticClass: "btn btn-primary",
                            },
                            [_vm._v("學習熱度")]
                          ),
                        ]
                      ),
                      !_vm.isObjectEmpty(_vm.learningPassionData) &&
                      _vm.showLayout === _vm.passion
                        ? _c("vip-learning-passion", {
                            attrs: {
                              learningPassionData: _vm.learningPassionData,
                            },
                          })
                        : _vm.showLayout === _vm.analysis
                        ? _c("vip-progress-analysis", {
                            attrs: {
                              materialAnalysisData: _vm.materialAnalysisData,
                              levelClassesCountData: _vm.levelClassesCountData,
                              learningAnalysisData: _vm.learningAnalysisData,
                              analysisOutlineData: _vm.analysisOutlineData,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "summerCamp",
                size: "md",
                centered: "",
                hideHeaderClose: true,
                "hide-footer": "",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "summerCamp-modal",
                },
                [
                  _c("h2", [_vm._v("我的結業證書")]),
                  _c("p", [_vm._v("即將下載暑期結業證書")]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          attrs: {
                            type: "button",
                          },
                          on: {
                            click: _vm.closeSummerCampModal,
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          attrs: {
                            type: "button",
                          },
                          on: {
                            click: _vm.downLoadCertificate,
                          },
                        },
                        [_vm._v("確認下載")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }