var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "wuwow card-body card-bg",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "are-progress-type d-flex align-items-center justify-content-center",
          },
          [
            _c("font-awesome-icon", {
              staticClass: "title-btn",
              class: [
                _vm.year <= _vm.progress_begin_year ? "title-btn-disable" : "",
              ],
              attrs: {
                icon: "caret-left",
                size: "2x",
              },
              on: {
                click: function ($event) {
                  _vm.onYearChange(
                    "prev",
                    !(_vm.year <= _vm.progress_begin_year)
                  )
                },
              },
            }),
            _c(
              "span",
              {
                staticClass: "title-y text-center",
              },
              [_vm._v(" " + _vm._s(_vm.year) + _vm._s(_vm.$t("year")))]
            ),
            _c("font-awesome-icon", {
              staticClass: "title-btn",
              class: [_vm.year >= _vm.this_year ? "title-btn-disable" : ""],
              attrs: {
                icon: "caret-right",
                size: "2x",
              },
              on: {
                click: function ($event) {
                  _vm.onYearChange("next", !(_vm.year >= _vm.this_year))
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "card-body-title",
          },
          [_vm._v(_vm._s(_vm.chart_title))]
        ),
        _c(
          "div",
          {
            staticClass: "are-progress-chart are-progress-chart-other",
          },
          [
            _c("line-chart", {
              key: _vm.options.scales.yAxes[0].ticks.max,
              attrs: {
                "chart-data": _vm.dataCollection,
                options: _vm.options,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }