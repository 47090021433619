var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow card-body",
    },
    [
      _c(
        "div",
        {
          staticClass: "card-body-top card-body-top-passion",
        },
        [
          _c(
            "span",
            {
              staticClass: "title-avg",
            },
            [_vm._v(_vm._s(_vm.learningPassionData.thirtyDaysAverage))]
          ),
          _c(
            "span",
            {
              staticClass: "title-unit mr-2",
            },
            [_vm._v(_vm._s(`${_vm.$t("class.class")}/${_vm.$t("day")}`))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "are-progress-type d-flex align-items-center justify-content-center",
        },
        [
          _c("font-awesome-icon", {
            staticClass: "title-btn",
            class: {
              "title-btn-disable": _vm.year <= _vm.progress_begin_year,
            },
            attrs: {
              icon: "caret-left",
              size: "2x",
            },
            on: {
              click: function ($event) {
                _vm.onYearChange("prev", !(_vm.year <= _vm.progress_begin_year))
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "title-y text-center",
            },
            [_vm._v(" " + _vm._s(`${_vm.year}${_vm.$t("year")}`))]
          ),
          _c("font-awesome-icon", {
            staticClass: "title-btn",
            class: {
              "title-btn-disable": _vm.year >= _vm.this_year,
            },
            attrs: {
              icon: "caret-right",
              size: "2x",
            },
            on: {
              click: function ($event) {
                _vm.onYearChange("next", !(_vm.year >= _vm.this_year))
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "are-progress-chart",
        },
        [
          _c("line-chart", {
            key: _vm.options.scales.yAxes[0].ticks.max,
            attrs: {
              "chart-data": _vm.dataCollection,
              options: _vm.options,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "passion-chart-footer",
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                `${_vm.year} ${_vm.$t("year")}${_vm.$t(
                  "class.number_of_total_classes"
                )}`
              ) + ":"
            ),
          ]),
          _vm.learningPassionData.yearlyDatas &&
          _vm.learningPassionData.yearlyDatas[_vm.year]
            ? _c(
                "span",
                {
                  staticClass: "passion-chart-footer-value",
                },
                [
                  _vm._v(
                    _vm._s(_vm.learningPassionData.yearlyDatas[_vm.year].total)
                  ),
                ]
              )
            : _c(
                "span",
                {
                  staticClass: "passion-chart-footer-value",
                },
                [_vm._v("0")]
              ),
          _c("span", [_vm._v(_vm._s(_vm.$t("class.class")))]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }